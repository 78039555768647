<template>
<div class="row tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
    <div class="col-12">
        <MarketHeader :data="$route.params.name"></MarketHeader>
        <div class="tgju-widget light">
            <div class="list-widget widget-head-page list-row bold list-row-title">
                <div class="list-col l-title head-color">
                    <span class="with-icon">
                        <i class="uil uil-chart"></i>پروفایل
                        {{ title }}
                    </span>
                </div>
                <div v-for="(item, index) in marketNavPage" :key="index" v-if="!item.hide" class="list-col l-item pointer hover head-color">
                    <router-link :to="{name: item.name, params: { name: $route.params.name }, }" class="l-item-btn-color-blue">
                        {{ item.title }}
                    </router-link>
                </div>
                <div class="list-col l-title"></div>
                <div class="list-col l-item pointer hover head-color">
                    <a href="" class="l-item-btn-color-blue">
                        <div class="fa fa-question-circle l-item-btn-pages"></div>
                        راهنما
                    </a>
                </div>
            </div>
            <div class="tgju-widget-content full-padding overflow-hidden">
                <MarketArchive></MarketArchive>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// این ویو برای ماژول  بازار  پروفایل شاخص ، بخش آرشیو مورد استفاده قرار میگیرد
import MarketArchive from "@/components/Market/Profile/Archive";
import marketNavPage from "@/assets/static_market_nav_page";
import MarketHeader from '@/components/Mobile/Market/Profile/Header';

export default {
    name: "marketArchive",
    components: {
        MarketArchive,
        MarketHeader,
    },
    data: function () {
        return {
            title: "--",
            symbol: "--",
            marketNavPage,
        };
    },
    mounted() {
    },
};
</script>
